import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {
      driver_status: [],
      vehicle_types: [],
      loop_approval_statuses: [],
      access_options: [],
      access_types: [],
      trip_statuses: [],
      charter_statuses: []
    };
  },

  actions: {
    async getCharterStatuses({ commit }) {
      const data = await Vue.axios.get("constants/charter-statuses");
      if (data.success) {
        commit("update_charter_status", data.data);
        return data.data;
      }
    },
    async getDriverStatuses({ commit }) {
      const data = await Vue.axios.get("constants/driver-statuses");
      if (data.success) {
        commit("update_driver_status", data.data);
      }
    },
    async getVehicleTypes({ commit }) {
      const data = await Vue.axios.get("constants/vehicle-types");
      if (data.success) {
        commit("update_vehicle_types", data.data);
        return data.data;
      }
    },
    async getLoopApprovalStatuses({ commit }) {
      const data = await Vue.axios.get("constants/loop-approval-statuses");
      if (data.success) {
        commit("update_loop_approval_statuses", data.data);
      }
    },
    async getTripStatuses() {
      // eslint-disable-next-line
      const data = await Vue.axios.get("constants/trip-statuses");
      return data.data;
    },
    async getAccessOptions({ commit }) {
      const data = await Vue.axios.get("constants/access");
      if (data.success) {
        commit("update_access_options", data.data);
      }
    },
    async getLoopTypes({ commit }) {
      const data = await Vue.axios.get("constants/loop-types");
      if (data.success) {
        commit("update_loop_types", data.data);
      }
    },
    async getProvinces(_ctx, payload) {
      const data = await Vue.axios.get("constants/provinces", {
        params: payload
      });
      return data;
    }
  },
  mutations: {
    update_charter_status(state, data) {
      state.charter_statuses = data;
    },
    update_driver_status(state, data) {
      state.driver_status = data;
    },
    update_vehicle_types(state, data) {
      state.vehicle_types = data;
    },
    update_loop_approval_statuses(state, data) {
      state.loop_approval_statuses = data;
    },
    update_trip_statuses(state, data) {
      state.trip_statuses = data;
    },
    update_access_options(state, data) {
      state.access_options = data;
    },
    update_loop_types(state, data) {
      state.loop_types = data;
    }
  },
  getters: {
    charter_statuses: ({ charter_statuses }) => {
      return charter_statuses;
    },
    driver_status: ({ driver_status }) => {
      return driver_status;
    },
    vehicle_types: ({ vehicle_types }) => {
      return vehicle_types;
    },
    loop_approval_statuses: ({ loop_approval_statuses }) => {
      return loop_approval_statuses;
    },
    trip_statuses: ({ trip_statuses }) => {
      return trip_statuses;
    },
    access_options: ({ access_options }) => {
      return access_options;
    },
    loop_types: ({ loop_types }) => {
      return loop_types;
    }
  }
};
