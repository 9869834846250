import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page = 1, perPage = 5 }) {
      const data = await Vue.axios.get(`/dashboard/driver/broadcasts`, {
        params: { page: page, per_page: perPage, recipient_type: "driver" }
      });
      return data;
    },
    async create(_ctx, payload) {
      const { data } = await Vue.axios.post(
        "/dashboard/driver/broadcasts",
        payload
      );

      return data;
    }
  },

  mutations: {},

  getters: {}
};
