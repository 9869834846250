<template>
  <footer>
    <div class="text-center small">
      <div>
        Copyright © {{ year }}. Lula Loop (Pty) Ltd. All rights reserved.
      </div>
      <a
        class="font-weight-bold"
        href="http://www.lulaloop.co.za/privacy-policy"
        >Privacy
      </a>
      <span class="font-weight-lighter">and </span>
      <a class="font-weight-bold" href="http://www.lulaloop.co.za/terms-of-use"
        >Terms of Use
      </a>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: black;
}
</style>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>
