import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page, perPage, query }) {
      return await Vue.axios.get(
        `dashboard/organization/account?page=${page}&per_page=${perPage}&${query}`
      );
    },
    async fetchRequests(_ctx, { page, perPage, query }) {
      const params = {
        page: page,
        per_page: perPage,
        status_id: 2,
        ...query
      };
      return await Vue.axios.get(`dashboard/organization/account`, {
        params: params
      });
    },
    async saveSignupStatus(_ctx, { payload, orgID }) {
      const { data } = await Vue.axios.put(
        `dashboard/organization/${orgID}/status`,
        payload
      );
      return data;
    },
    async update(_ctx, { payload, orgID }) {
      const { data } = await Vue.axios.put(
        `dashboard/organization/account/${orgID}`,
        payload
      );
      return data;
    },
    async delete(_ctx, orgID) {
      const { data } = await Vue.axios.delete(
        `dashboard/organization/account/${orgID}`
      );
      return data;
    },
    async export(_ctx, { query }) {
      const { data } = await Vue.axios.get(
        `dashboard/organization/account/export?${query}`,
        { responseType: "blob" }
      );
      return data;
    },
    async fetchCharterZonalPricing(_ctx, { orgID }) {
      const { data } = await Vue.axios.get(
        `dashboard/organization/charter-zonal-pricing/${orgID}`
      );
      return data;
    },
    async fetchVehiclePricings() {
      return await Vue.axios.get(`dashboard/vehicle/pricings`);
    },
    async createCharterZonalPricing(_ctx, { payload }) {
      const { data } = await Vue.axios.post(
        `dashboard/organization/charter-zonal-pricing`,
        payload
      );
      return data;
    },

    async updateCharterZonalPricing(_ctx, { payload, ID }) {
      const { data } = await Vue.axios.put(
        `dashboard/organization/charter-zonal-pricing/${ID}`,
        payload
      );
      return data;
    },

    async deleteCharterZonalPricing(_ctx, { ID }) {
      const { message } = await Vue.axios.delete(
        `dashboard/organization/charter-zonal-pricing/${ID}`
      );
      return message;
    }
  }
};
