<template>
  <b-container fluid>
    <b-row id="default">
      <b-col class="bg-white d-none d-lg-block p-5">
        <b-container fluid class="h-100">
          <b-row class="h-100 justify-content-between">
            <b-col style="display: contents">
              <div style="width: 120px">
                <img class="img-fluid" src="@/assets/logo.png" />
              </div>
            </b-col>
            <b-col cols="12">
              <div id="container">
                <span id="text1"></span>
                <span id="text2"></span>
              </div>

              <svg id="filters">
                <defs>
                  <filter id="threshold">
                    <feColorMatrix
                      in="SourceGraphic"
                      type="matrix"
                      values="1 0 0 0 0
									0 1 0 0 0
									0 0 1 0 0
									0 0 0 255 -140"
                    />
                  </filter>
                </defs>
              </svg>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col class="d-flex flex-column p-3">
        <div class="m-auto">
          <slot />
        </div>
        <Footer />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Footer from "@/components/Layout/Footer";

export default {
  components: {
    Footer
  },
  mounted() {
    const elts = {
      text1: document.getElementById("text1"),
      text2: document.getElementById("text2")
    };

    const texts = [
      "Move the people",
      "Move the world",
      "❤️",
      "Also...",
      "it is",
      "what it is",
      "😮‍💨"
    ];

    const morphTime = 2;
    const cooldownTime = 0.5;

    let textIndex = texts.length - 1;
    let time = new Date();
    let morph = 0;
    let cooldown = cooldownTime;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];

    function doMorph() {
      morph -= cooldown;
      cooldown = 0;

      let fraction = morph / morphTime;

      if (fraction > 1) {
        cooldown = cooldownTime;
        fraction = 1;
      }

      setMorph(fraction);
    }

    function setMorph(fraction) {
      elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      fraction = 1 - fraction;
      elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      elts.text1.textContent = texts[textIndex % texts.length];
      elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    }

    function doCooldown() {
      morph = 0;

      elts.text2.style.filter = "";
      elts.text2.style.opacity = "100%";

      elts.text1.style.filter = "";
      elts.text1.style.opacity = "0%";
    }

    function animate() {
      requestAnimationFrame(animate);

      const newTime = new Date();
      const shouldIncrementIndex = cooldown > 0;
      const dt = (newTime - time) / 1000;
      time = newTime;

      cooldown -= dt;

      if (cooldown <= 0) {
        if (shouldIncrementIndex) {
          textIndex++;
        }

        doMorph();
      } else {
        doCooldown();
      }
    }

    animate();
  }
};
</script>

<style lang="scss" scoped>
#default {
  min-height: 100vh;
}

#container {
  filter: url(#threshold) blur(0.6px);
}
#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;

  font-size: 80pt;
  font-weight: bold;

  text-align: center;

  user-select: none;
}
</style>
