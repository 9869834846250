import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.js";
import driver from "./driver.js";
import constants from "./constants.js";
import vehicle from "./vehicle.js";
import loop from "./loop.js";
import schedule from "./schedule.js";
import organization from "./organization.js";
import dashboard from "./dashboard.js";
import trips from "./trips.js";
import reports from "./reports.js";
import operators from "./operators.js";
import commuters from "./commuters.js";
import charters from "./charters.js";
import pricing from "./pricing.js";
import driver_broadcast from "./driver-broadcast.js";
import commuter_broadcast from "./commuter-broadcast.js";
import bookings from "./bookings.js";
import users from "./users.js";
import dayHires from "./day-hires.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    driver,
    constants,
    vehicle,
    loop,
    schedule,
    organization,
    dashboard,
    trips,
    reports,
    operators,
    commuters,
    charters,
    dayHires,
    pricing,
    driver_broadcast,
    commuter_broadcast,
    bookings,
    users
  }
});
